import { useUsersStore } from "@/stores/users";
import { isEnvironment } from "@/utils/helpers/environment";
import axios from "@/utils/axios";
import { logger } from "@/utils/helpers/logger";
import type { AxiosError, AxiosResponse } from "axios";
import { useSessionsStore } from "@/stores/sessions";

export const $api = {
    get: async (url: string, params?: null | {}, config?: null | {}) => {
        logger.notice("AxiosGet", { url, params, config });
        return await axios
            .get(url, {
                params,
                headers: authHeader(url),
                ...config
            })
            .then(handleResponse)
            .catch(handleError);
    },
    post: async (url: string, params?: null | {}, body?: any, config?: null | {}) => {
        logger.notice("AxiosPost", { url, params, body, config });
        return await axios
            .post(url, body, {
                params,
                headers: authHeader(url),
                ...config
            })
            .then(handleResponse)
            .catch(handleError);
    },
    put: async (url: string, params?: null | {}, body?: any, config?: null | {}) => {
        logger.notice("AxiosPut", { url, params, body, config });
        return await axios
            .put(url, body, {
                params,
                headers: authHeader(url),
                ...config
            })
            .then(handleResponse)
            .catch(handleError);
    },
    delete: async (url: string, params?: null | {}, config?: null | {}) => {
        logger.notice("AxiosDelete", { url, params, config });
        return await axios
            .delete(url, {
                params,
                headers: authHeader(url),
                ...config
            })
            .then(handleResponse)
            .catch(handleError);
    }
    // put: request('PUT'),
    // delete: request('DELETE')
};

export { getApiUrl, apiErrorToString };
function getApiUrl(): string {
    return import.meta.env.VITE_API_URL;
}

function apiErrorToString(error: AxiosError): string {
    const data = error.response?.data as any;
    if (data && data.error && data.error.message) {
        return data.error.message;
    } else {
        return error.toString();
    }
}
// helper functions

function authHeader(url: string) {
    // return auth header with jwt if user is logged in and request is to the api url
    const { session } = useSessionsStore();
    const isLoggedIn = !!session?.token;
    const isApiUrl = url.startsWith(getApiUrl());
    if (isLoggedIn && isApiUrl) {
        return { Authorization: `Bearer ${session.token}` };
    } else {
        return {};
    }
}

function handleResponse(response: AxiosResponse) {
    logger.notice("AxiosResponse", response);
    return response;
}
function handleError(error: AxiosError) {
    logger.error("AxiosError", error);
    if (error.response?.status !== 200) {
        const { session, logout } = useSessionsStore();
        if ([401].includes(error.response?.status as number) && session) {
            logout();
        }

        // const error = (data && data.message) || response.statusText;
        // return Promise.reject(error);
    }
    return error;
}
